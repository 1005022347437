<template>
  <a-modal v-model="visible" title="Редактирование" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        Отмена
      </a-button>
      <a-button key="submit" type="primary" @click.prevent.stop="submit" :disabled="isDisabled">
        Изменить
      </a-button>
    </template>
    <a-form-model :model="form">
      <a-form-model-item label="Статус" required>
        <a-select v-model="form.active" placeholder="выберите статус">
          <a-select-option v-for="type in statuses" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Название (ru)">
        <a-input v-model="form.ru.title"/>
      </a-form-model-item>
      <a-form-model-item label="Название (ua)">
        <a-input v-model="form.ua.title"/>
      </a-form-model-item>
      <a-form-model-item label="Количество уровней" required>
        <a-input  v-model.number="form.count_level"/>
      </a-form-model-item>
      <a-form-model-item label="Количество бонусных уровней" required>
        <a-input  v-model.number="form.bonus_level"/>
      </a-form-model-item>
      <a-form-model-item label="Период(дни)" required>
        <a-input  v-model.number="form.days"/>
      </a-form-model-item>
      <a-form-model-item label="Цена(грн)" required>
        <a-input  v-model.number="form.price"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import statuses from '@/entities/statuses'

export default {
  name: 'EditPromotionsModal',

  data() {
    return {
      statuses,
      form: {
        value: '',
        key: '',
        ru: {},
        ua: {},
      },
      visible: false,
      fileListMain: [],
    }
  },

  computed: {
    isDisabled() {
      const { form } = this
      return !(form.days && form.price && `${form.bonus_level}` && `${form.active}` && form.count_level)
    },
  },

  methods: {
    async open(id) {
      this.visible = true

      if (id) {
        try {
          this.form = (await this.$services.get(`admin/tariff_level/${id}`)).data.data.value
        } catch (e) {
          console.log(e)
        }
      }
    },

    close() {
      this.visible = false
      this.form = {
        ru: {
          title: '',
        },
        ua: {
          title: '',
        },
      }
      this.$emit('getList')
    },

    async submit() {
      try {
        this.form.id ? await this.$services.put(`admin/tariff_level/${this.form.id}`, this.form) : await this.$services.post('admin/tariff_level', this.form)
        this.close()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
