<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Настройка: Тарифы публикаций</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Пакеты продвижения</h5>
        </div>
        <div class="d-flex flex-column justify-content-center ml-3">
          <a class="btn btn-primary" @click="$refs.editModal.open()">Добавить</a>
        </div>
      </div>
      <div class="card-body">
        <promotions-table :list="list" :paginate="pagination" @handleTableChange="handleTableChange" @getList="getList" />
      </div>
    </div>
    <edit-promotions-modal ref="editModal" @getList="getList" />
  </div>
</template>

<script>
import PromotionsTable from '@/components/functional/promotions/PromotionsTable'
import EditPromotionsModal from '@/components/functional/promotions/EditPromotionsModal'

export default {
  name: 'index',

  components: {
    PromotionsTable,
    EditPromotionsModal,
  },

  data() {
    return {
      list: [],
      search: '',
      pagination: {
        current: 1,
        total: 1,
      },
    }
  },

  created() {
    this.getList()
  },

  methods: {
    async getList(page = this.pagination.current) {
      const params = {
        page,
        ...this.search ? { search: this.search } : {},
      }

      try {
        const data = (await this.$services.get('admin/tariff_level', { params })).data.data.values

        this.list = data.data
        this.pagination = {
          current: data.pagination.current_page,
          total: data.pagination.total,
          pageSize: data.pagination.per_page,
        }
      } catch (e) {
        console.log(e)
      }
    },

    handleTableChange(pagination) {
      this.pagination.current = pagination.current
      this.getList()
    },
  },
}
</script>
