export default [
  {
    name: 'Активно',
    id: 1,
  },
  {
    name: 'Не активно',
    id: 0,
  },
]
